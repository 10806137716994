import * as React from 'react'
import {
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    Slide
} from '@mui/material/'

import Snackbar from './snackbar'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

export default function DialogSlide(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleAssignFiles = () => {

  }

  return (
    <div>
      <Button variant="contained" style={{height: '50px'}} onClick={handleClickOpen}>
        {props.label}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
       
      >
        <DialogContent style={{paddingRight: '200px'}}>
          <DialogContentText id="alert-dialog-slide-description">
            Assigning Files to FOIA Case ####
          </DialogContentText>
            <br/>
          <DialogContentText>
            <FormGroup>

            <FormControlLabel control={<Checkbox defaultChecked />} label="Remove Duplicate Files" />

            </FormGroup>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Snackbar />
          {/* <Button onClick={handleAssignFiles} variant="outlined" >Assign Files</Button> */}
        </DialogActions>
        
      </Dialog>
    </div>
  );
}
