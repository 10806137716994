import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });
    
      const { vertical, horizontal, open } = state;
    
      const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
      };
    
      const handleClose = () => {
        setState({ ...state, open: false });
      };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Button variant="contained" style={{width: '50%', textAlign: 'center', margin: '0 auto'}} 
        onClick={handleClick({
          vertical: 'top',
          horizontal: 'right',
        })} >
        Assign Files
      </Button>
      <br/>
      <Snackbar 
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Files Assign Successfully!"
            key={vertical + horizontal}
  
      >
         <Alert severity="success">Files Assigned Successfully!</Alert>
      </Snackbar>
      
   
    </Stack>
  );
}
