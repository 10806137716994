import * as React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
  Tooltip,
} from "@mui/material";

import PDF from "../assets/images/PDF-Icon.png";
import { color } from "@mui/system";
import { Opacity } from "@mui/icons-material";

export default function TableComponent(props) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Answer</TableCell>
            <TableCell align="center">Context</TableCell>
            <TableCell align="center">Score</TableCell>
            <TableCell align="center">File</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow
              key={row.Name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ color: "#016bb5" }}
              >
                {row.Answer}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.Context}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.Score}
              </TableCell>
              <TableCell component="th" scope="row" className="download">
                <Tooltip title="Download Source" arrow>
                  <span style={{ float: "right", textAlign: "center" }}>
                    <img style={{ width: "15%" }} src={PDF} />
                    <br />
                    <small style={{ opacity: "0.6" }}>
                      <i>Download</i>
                    </small>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
