import React from "react"

export default function TypingAnimation() {
  return (

        <div class="typing-demo">
         Intelligent Document Management
         </div>
    
  )
}
