import React from "react"
import { StyledEngineProvider } from "@mui/material/styles"
import "./assets/sheets/main.scss"
import AppWrapper from "./template/appTemplate"
import Search from "./search"

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="appContainer"  >
        <AppWrapper >
          <Search />
        </AppWrapper>
      </div>
    </StyledEngineProvider>
  )
}

export default App
